body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.carousel-control-prev-icon {
  background-image: url('./img/home/left_arrow.png');
}

section {
  position: relative;
}

.section-one {
  box-sizing: border-box;
  overflow: hidden;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 70px;
  width: 100%;
  max-height: var(--one-max-height);
  min-height: var(--one-min-height);
  margin-top: calc(var(--padding-top)* -1);
  padding-top: var(--padding-top);
  --padding-top: calc(var(--r-localnav-height) + var(--r-globalnav-height));
  --one-max-height: 70px;
  --one-min-height: 1vh;
  background-color: #ffffff;
}

.section-gray {
  position: relative;
  width: 100% !important;
  
  padding: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: #918686;
}



