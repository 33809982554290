@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wdth,wght@0,75..100,300..800;1,75..100,300..800&display=swap');

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-lang {
        display: none;
    }
}
@media (max-width: 992px) {
    .navbar-expand-lg .navbar-lang-max {
        display:none;
    }
}
.carousel-indicators [data-bs-target] {
    border-radius: 50%;
    width: 2vh;
    height: 2vh;
    margin-right: 10px;
    margin-left: 10px;
    opacity: 1;
}  

.carousel-indicators .active {
    background-color: #00246e;
}

.carousel-caption {
    top: 50%;
    transform: translateY(-50%);
    bottom: initial;
    text-align: right;

}

.raleway-title-medium {
    color: #f68889;
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 3.6vw;
    line-height: 1;
    text-shadow: 0px 0px 1.2vw #00246e, 0px 0px 1.2vw #00246e;
}
.raleway-title-black {
    color: #f68889;
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    font-size: 5vw;
    line-height: 1;
    text-shadow: 0px 0px 1.2vw #00246e, 0px 0px 1.2vw #00246e;
}

.raleway-text {
    color: #fff;
   
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 2.4vw;
    line-height: 1;
    text-shadow: 0px 0px 1.2vw #00246e, 0px 0px 1.2vw #00246e;
}

.open-sans-text {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 2.2vw;
    font-variation-settings:
      "wdth" 100;
    line-height: 1;
    color: #fff;
    text-shadow: 0px 0px 1.2vw #00246e, 0px 0px 1.2vw #00246e, 0px 0px 1vw #00246e;
}

.raleway-semibold-red {
    color: #aa2c2d;
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 2vw;
    line-height: 1;
}

.raleway-semibold-red .size {
    font-size: 3vw;
}

.raleway-semibold-blue {
    color: #00246e;
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 2vw;
    line-height: 1;
}

.raleway-semibold-blue .size {
    font-size: 3vw;
}

.image-inner{
    position: relative;
    width: 100%;
    overflow: hidden;
}   

.image-item {
    position: relative;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
}

.image-caption {
    position: absolute;
    right: 5%;
    left: 54%;
    top: 50%;
    transform: translateY(-50%);
    bottom: initial;
    text-align: right;
}

.image-caption-top {
    position: absolute;
    right: 5%;
    left: 54%;
    top: 20%;
    transform: translateY(-50%);
    bottom: initial;
    text-align: right;

}

.image-caption-fluid {
    position: absolute;
    top: 60%;
    right: 5%;
    left: 5%;
    transform: translateY(-50%);
}
.image-caption-left {
    position: absolute;
    top: 50%;
    right: 5%;
    left: 5%;
    transform: translateY(-50%);
}

.image-caption-right {
    position: absolute;
    top: 50%;
    right: 5.5%;
    left: 5%;
    transform: translateY(-50%);
    display: grid;
    justify-content: end;
}

.section-caption {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
}

.section-caption-fluid {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 4%;
    margin-bottom: 4%;
}
.image-caption-center {
    position: absolute;
    right: 5%;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    bottom: initial;
    text-align: center;
}

.open-sans-extrabold {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
    color: #fff;
    font-size: 2.4vw;
    text-align: justify;
}

.open-sans-extrabold-red {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
    color: #aa2c2d;
    font-size: 1.5vw;
    line-height: 5;
}

.open-sans-medium {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
    color: #fff;
    font-size: 2vw;
    text-align: justify;
    text-justify: inter-word;
    text-align-last:justify;
}

.open-sans-regular {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
    color: #333333;
    font-size: 1.2vw;
    text-align: justify;
    
}

.open-sans-regular-footer {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    color: #fff;
    font-size: 1vw;
}
.open-sans-bold-footer {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    color: #fff;
    font-size: 1vw;
}

.open-sans-italic {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: italic;
    font-variation-settings: "wdth" 100;
    color: #333333;
    font-size: 1vw;
}
  
.open-sans-medium strong{
    font-weight: 800;
}

.col-width-center{
    width: 4%;
    display: grid;
    place-items: start center;
}

.col-width-card{
    width: 30vw;
    place-items: start center;
}
.col-width-card-center{
    width: 2vw;
    place-items: start center;
}

.gradient-line {
    border-left: solid #092e6e;
    height: 95%;
}

.btn-outline-custom{
    padding: 1% 10%;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
    border-radius: 2rem;
    font-size: 1.2vw;
    --bs-btn-color: #aa2c2d;
    --bs-btn-border-color: #aa2c2d;
    --bs-btn-hover-bg: #aa2c2d;
    --bs-btn-hover-border-color: #aa2c2d;
    --bs-btn-active-bg: #aa2c2d;
    --bs-btn-active-border-color: #aa2c2d;
    --bs-btn-disabled-color: #aa2c2d;
    --bs-btn-disabled-border-color: #aa2c2d;
}

.btn-outline-custom2{
    padding: .2vw 2vw;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
    border-radius: 2rem;
    font-size: 1.1vw;
    background-color: #aa2c2d;
    --bs-btn-color: #ffffff;
    --bs-btn-border-color: #aa2c2d;
    --bs-btn-hover-color: #aa2c2d;
    --bs-btn-hover-bg: #ffffff;
    --bs-btn-hover-border-color: #aa2c2d;
    --bs-btn-active-bg: #ffffff;
    --bs-btn-active-color: #aa2c2d;
    --bs-btn-active-border-color: #aa2c2d;
    --bs-btn-disabled-color: #aa2c2d;
    --bs-btn-disabled-border-color: #aa2c2d;
}
.center-items {
    text-align: center;
  }
.separator {
    display: flex;
    align-items: center;
    text-align: center;
  }
  
  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 0.2vw solid #aa2c2d;
  }
  
  .separator:not(:empty)::before {
    margin-right: 1.25em;
  }
  
  .separator:not(:empty)::after {
    margin-left: 1.25em;
  }

  .card {
    padding: 1vw;
    border-radius: 0em;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 31%, rgba(0,0,0,.5) 31%);
    border:rgba(0, 0, 0, 0);
    //background-color: rgba(0,0,0,.5);
    align-items: center;
    
  }
  .card-image{
    width: 10.5vw;
  }

.card-text {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
    text-align: center;
    color: #fff;
    font-size: 1.2vw;
    line-height: 1.2;
    width: 23vw;
}

.center-items{
    height: 8vw;
    align-content: center;
}

.img-property{
    height: 30vw;
    width: 110%;
}

.col-margin{
    margin-top: 2vw;
}
.row-margin{
    margin-left: 5%;
    margin-right: 5%;
}
.navbar {
    --bs-navbar-padding-y: none;
}

.logo-size{
    width: 58%;
}

.nav {
    --bs-nav-link-padding-x: 0.4rem;
}
.nav-link{
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
    line-height: 1;
    color: #00246e;
    background-color: inherit;
    display: flex;
    justify-content: flex-end;
}

.nav-pills .nav-link.active{
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
    line-height: 1;
    color: #aa2c2d;
    background-color: inherit;
}
.navbar-nav .nav-link.active{
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
    line-height: 1;
    color: #aa2c2d;
    background-color: inherit;
}
.nav-link:hover, .nav-link:focus {
    color: #aa2c2d; 
}

.navbar-toggler{
    border-color: #00246e;
    color: rgba(0, 36, 110, 0.65);
}
.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 36, 110, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e")
}

.bg-menu{
    background-image: url('../img/menu/bg_menu.png');
    background-size: auto 100%;
}

.row-content-center{
    justify-content: center;
}

.footer-left-center{
    align-content: center;
}

.footer-center{
    align-content: center;
    text-align: center;
}

.footer-right-center{
    display: flex;
    text-align: end;
    justify-content: flex-end;
    align-items: center;
}

textarea { 
    height: 10vw; 
}

input { 
    height: 2vw; 
}

.form-control {
    border-radius: inherit;
}

.mb-3 {
    margin-bottom: 1vw !important;
}

.btn-contact-group{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.btn-admin {
    padding: 1vw 6vw;
    border-radius: 0em;
    border: 0rem;
}

.btn-admin:enabled {
    background-image: url('../img/contact/btn_admin_off.png') !important;
    background-size: 100% auto;
    background-color: Transparent;
    background-repeat: no-repeat;
    background-position-y: center;
}
.btn-admin:hover{
    background-image: url('../img/contact/btn_admin_on.png') !important;
    background-size: 100% auto;
    background-color: Transparent;
    background-repeat: no-repeat;
    background-position-y: center;
}

.btn-mail {
    padding: 1vw 6vw;
    border-radius: 0em;
    border: 0rem;
}

.btn-mail:enabled {
    background-image: url('../img/contact/btn_mail_off.png') !important;
    background-size: 100% auto;
    background-color: Transparent;
    background-repeat: no-repeat;
    background-position-y: center;
}
.btn-mail:hover{
    background-image: url('../img/contact/btn_mail_on.png') !important;
    background-size: 100% auto;
    background-color: Transparent;
    background-repeat: no-repeat;
    background-position-y: center;
}

.video {
    position: relative;
}
.btn-play {
    padding: 3.5vw;
    border: 0rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%)
}


.btn-play:enabled {
    background-image: url('../img/services/button_play_off.png') !important;
    background-size: 100% auto;
    background-color: Transparent;
}
.btn-play:hover{
    background-image: url('../img/services/button_play_on.png') !important;
    background-size: 100% auto;
    background-color: Transparent;
}

.btn-facebook {
    padding: 1vw 0.7vw;
    border-radius: 0em;
    border: 0rem;
}

.btn-facebook:enabled {
    background-image: url('../img/footer/btn_facebook_off.png') !important;
    background-size: 100% auto;
    background-color: Transparent;
    background-repeat: no-repeat;
    background-position-y: center;
}
.btn-facebook:hover{
    background-image: url('../img/footer/btn_facebook_on.png') !important;
    background-size: 100% auto;
    background-color: Transparent;
    background-repeat: no-repeat;
    background-position-y: center;
}

.btn-x {
    padding: 1vw 0.6vw;
    border-radius: 0em;
    border: 0rem;
}

.btn-x:enabled {
    background-image: url('../img/footer/btn_x_off.png') !important;
    background-size: 100% auto;
    background-color: Transparent;
    background-repeat: no-repeat;
    background-position-y: center;
}
.btn-x:hover{
    background-image: url('../img/footer/btn_x_on.png') !important;
    background-size: 100% auto;
    background-color: Transparent;
    background-repeat: no-repeat;
    background-position-y: center;
}

.logo-world {
    width: 36px;
    height: 28px;
}

.carousel-control-prev-icon, 
.carousel-control-next-icon {
    width: 4rem;
    height: 4rem;
}

.alink{
    color: unset; 
    text-decoration: none;
}
